body {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
  background: linear-gradient(180deg, #E5E7F5 0%, #FFFFFF 100%);
  min-height: 100vh;
  overflow-y: scroll;
}


